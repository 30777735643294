function initIframe () {
  window.addEventListener('message', function (e) {
    let iframeEvent = e.data.toString()
    if (iframeEvent.indexOf('PND') === 0) {
      let iframeHeight = iframeEvent.substring(3)
      document.getElementById('frame').style.height = iframeHeight + 'px'
    }
  }, false)
};

export default initIframe
