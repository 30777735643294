import Vue from 'vue'
import BiographiesFilter from './components/BiographiesFilter'
import BiographiesFilterSelect from './components/BiographiesFilterSelect'

import '@/utils/axios'

import '@/assets/scss/main.scss'

import initSlider from './modules/slider.js'
import initTabs from './modules/tabs.js'
import initSearch from './modules/search.js'
import initIframe from './modules/iframe'

// eslint-disable-next-line no-new
new Vue({
  components: {
    BiographiesFilter,
    BiographiesFilterSelect,
  },
}).$mount('#App')

initSlider()

initIframe()

initSearch()

initTabs()
