<template>

  <div class="u-flex u-flex-column">
    <div class="c-component-filter">
      <biographies-filter-menu :items="alphabet" @letterClick="handleLetterClick"/>

      <p v-if="selectedLetters.length == 1 ">
        <span  class="u-text-medium-gray u-text-h2">{{ selectedLetters[0] }}</span>
        ({{ filteredBiographies.length }})
      </p>
      <p v-else>
        <span  class="u-text-medium-gray u-text-h2"> Biografies </span>
        ({{ filteredBiographies.length }})
      </p>
    </div>
    <ul class="o-list u-mt-large">
      <li v-for="(bio, id) in filteredBiographies" :key="id" class="o-list__item">
        <div class="c-biografiesfilter__biography u-mb-large">
          <a :href="bio.url" class="u-m-small">
            <v-lazy-image :src="bio.image"
                          :alt="addAltText(bio.name, bio.surname, bio.conjunction, bio.second_surname)"
                          class="c-image-max-width u-image-cover u-mb-small"/>
          </a>
          <div class="u-flex u-flex-column">
            <p class="u-text-h4 u-mb-none">
              <a :href="bio.url" class="u-text-no-underline u-family-serif
                u-text-branding-primary-dark u-text-bold">
                {{ formatedName(bio) }}
              </a>
            </p>
            <p class="u-family-serif u-text-medium-gray">
              ({{ bio.birth_date }}-{{ bio.dead_date }})
            </p>
            <p class="u-text-medium-gray">
              {{ bio.short_description | truncate(250) }}
            </p>
          </div>
        </div>
      </li>
    </ul>

    <p v-if="!filteredBiographies.length">Sense resultats</p>
    <biographies-filter-menu :items="alphabet" @letterClick="handleLetterClick"/>
  </div>
</template>

<script>
import BiographiesFilterMenu from '@/components/BiographiesFilterMenu'
import VLazyImage from 'v-lazy-image'

export default {
  name: 'BiographiesFilter',
  components: {
    BiographiesFilterMenu,
    VLazyImage,
  },
  filters: {
    truncate (string, value) {
      return string.substring(0, value) + ' ...'
    },
  },
  props: {
    json: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      selectedLetters: [],
      biographies: JSON.parse(this.json),
      alphabet: [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
        'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X',
        'Y', 'Z',
      ],
    }
  },
  computed: {
    filteredBiographies () {
      return this.biographies.filter(field =>
        this.selectedLetters.includes(field['surname'].charAt(0))
      )
    },
  },
  mounted () {
    this.selectedLetters = this.alphabet
    this.$root.$on('letterSelect', data => {
      this.selectedLetters = data
    })
  },
  methods: {
    handleLetterClick (lettersArray) {
      this.selectedLetters = lettersArray
    },
    addAltText (name, surname, conjunction, secondSurname) {
      if (conjunction === 'i') {
        return `${name} ${surname} i ${secondSurname} `
      } else if (conjunction === '-') {
        return `${name} ${surname}-${secondSurname} `
      } else {
        return `${name} ${surname} ${secondSurname} `
      }
    },
    formatedName (bio) {
      if (bio.second_surname) {
        if (bio.conjunction === 'i') {
          return `${bio.surname} i ${bio.second_surname}, ${bio.name}`
        } else if (bio.conjunction === '-') {
          return `${bio.surname}-${bio.second_surname}, ${bio.name}`
        } else {
          return `${bio.surname} ${bio.second_surname}, ${bio.name}`
        }
      }
      return `${bio.surname}, ${bio.name}`
    },
  },
}
</script>
