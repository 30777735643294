/**
 * Add necessary axios headers to perform HTTP requests.
 */

window.axios = require('axios')
const token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
  window.axios.defaults.headers.common['X-CSRFToken'] = token.content
} else {
  console.error('CSRF token not found!')
}
