<template>
  <div class="u-flex u-flex-column c-filter-component">
    <p class="u-family-serif u-text-bold u-text-medium-gray u-mb-tiny">Inicial</p>
    <select v-model="selected" class="c-select" @change="onChange (selected)">
      <option/>
      <option v-for="item in alphabet"
              :key="item"
              class="c-biografiesfilter__menuitem u-text-branding-primary-dark u-flex-auto
          u-mh-tiny u-family-serif u-text-bold">{{ item }}</option>
    </select>
    <p class="u-family-serif u-text-bold u-mt-small u-mb-tiny  u-text-medium-gray u-mb-tiny">
      Biografia
    </p>
    <select v-model="selectedBio" class="c-select" @change="onChangeBio (selectedBio)">
      <option value=""> {{ filteredBiographies.length }} biografies</option>
      <option v-for="(bio, id) in filteredBiographies" :key="id" :value="bio.url">
        {{ bio.surname }}, {{ bio.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'BiographiesFilterSelect',
  filters: {
    truncate (string, value) {
      return string.substring(0, value) + ' ...'
    },
  },
  props: {
    json: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      selected: [],
      selectedBio: [],
      selectedLetters: [],
      biographies: JSON.parse(this.json),
      alphabet: [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
        'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X',
        'Y', 'Z',
      ],
      item: '',
    }
  },
  computed: {
    filteredBiographies () {
      return this.biographies.filter(field =>
        this.selectedLetters.includes(field['surname'].charAt(0))
      )
    },
  },
  mounted () {
    this.selectedLetters = this.alphabet
  },
  methods: {
    onChange (selected) {
      if (selected !== '') {
        this.selectedLetters = selected
        this.$root.$emit('letterSelect', selected)
      } else {
        this.selectedLetters = this.alphabet
        this.$root.$emit('letterSelect', this.selectedLetters)
      }
    },
    onChangeBio (selectedBio) {
      if (selectedBio !== '') window.location = selectedBio
    },
  },
}
</script>
