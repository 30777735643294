function initSlider () {
  var slideIndex = 0

  function showSlides () {
    var slides = document.querySelectorAll('.js-slider-item')
    if (slides.length) {
      if (slideIndex >= slides.length) {
        slideIndex = 0
      }
      hideAll(slides)
      showNext(slides, slideIndex)
      setTimeout(() => {
        changeBackground(slideIndex)
        slideIndex++
      }, 2000)
    }
  }

  function showNext (slides, next) {
    slides[next].classList.remove('u-hidden')
  }

  function hideAll (slides) {
    slides.forEach(slide => {
      slide.classList.add('u-hidden')
    })
  }

  function changeBackground (index) {
    let activeSlide = document.querySelectorAll('.js-slider-item')[index]
    let slideImgSrc = activeSlide.getElementsByTagName('img')[0].src
    document.getElementById('c-slider').style.backgroundImage = 'url(' + slideImgSrc + ')'
  }
  showSlides()
  setInterval(showSlides, 6000)
}

export default initSlider
