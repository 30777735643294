function initTabs () {
  window.addEventListener('load', function () {
    var optionActiveClassName = 'c-tab__option--active'
    var tabOptions = document.querySelectorAll('.js-tab-option')
    var tabContents = document.querySelectorAll('.js-tab-content')
    if (tabOptions.length) {
      tabOptions[0].classList.add(optionActiveClassName)
      tabContents[0].classList.remove('u-hidden')
      for (var i = 0; i < tabOptions.length; i++) {
        tabOptions[i].addEventListener('click', tabClick)
      }
    }
    function tabClick (tabClickEvent) {
      for (i = 0; i < tabOptions.length; i++) {
        tabOptions[i].classList.remove(optionActiveClassName)
      }
      var clickedTab = tabClickEvent.currentTarget
      clickedTab.classList.add(optionActiveClassName)
      tabClickEvent.preventDefault()
      for (i = 0; i < tabContents.length; i++) {
        tabContents[i].classList.add('u-hidden')
      }
      var anchorReference = tabClickEvent.target
      var activePaneId = anchorReference.getAttribute('href')
      var activePane = document.querySelector(activePaneId)
      activePane.classList.remove('u-hidden')
    }
  })
}

export default initTabs
