<template>
  <div class="u-flex u-flex-column">
    <hr class="u-mb-tiny u-mt-small">
    <div class="u-flex u-flex-wrap u-flex-self-auto">
      <a
        v-for="item in items"
        :key="item"
        class="c-biografiesfilter__menuitem u-text-branding-primary-dark u-flex-auto
          u-mh-tiny u-family-serif u-text-bold"
        @click="handleClick([item])">
        {{item}}
      </a>
      -
      <a
        class="c-biografiesfilter__menuitem u-text-branding-primary-dark u-flex-auto
        u-mh-tiny u-family-serif u-text-bold"
        @click="handleClick(items)">
        Sense filtre
      </a>
    </div>
    <hr class="u-mb-small u-mt-tiny">
  </div>
</template>

<script>
export default {
  name: 'BiographiesFilterMenu',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleClick (lettersArray) {
      this.$emit('letterClick', lettersArray)
    },
  },
}
</script>
