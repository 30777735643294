function initSearch () {
  function display () {
    var search = document.querySelector('.js-search-block')
    search.style.display = search.style.display === 'block' ? '' : 'block'
  }
  // Event list per cada clic a la lupa
  document.querySelector('.js-search-toggle').addEventListener('click', display)
}

export default initSearch
